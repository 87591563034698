import { codInstFinancEmisorPago, codPaisReceptor } from "../defaultConst";
import { cleanRut, convertirRutAId } from "../cleanInputMask";
import axios from "axios";
import configuration from "../../Configuration";
import { useState } from "react";
export const HandleSubmitFormFormaPago = async ({
	values,
	changeLoading,
	idPersona,
	idCliente,
	alternativa,
	informante,
	history,
}) => {
	const cantidadCuentas = 1;

	/*if (values.radioButtonS !== "") {
		cantidadCuentas = 1;
	} else {
		cantidadCuentas = 0;
	}*/

	let arrCuentas = [];

	/* Si el usuario seleccionó Cuenta Bancaria como forma de pago primaria */
	if (values.radioButtonP === "0") { 
		arrCuentas.push({
			institucion: values.institucionP,
			numeroCuenta: values.numeroCuentaP,
			radioButton: values.radioButtonP,
			rut: values.rutP,
			tipoCuenta: values.tipoCuentaP,
			tipoCuentaUso: "P",
		});
		
	} 
	/* El usuario seleccionó Vale Vista como forma de pago primaria */
	else {
		arrCuentas.push({
			institucion: "99",
			numeroCuenta: "",
			radioButton: values.radioButtonP,
			rut: "",
			tipoCuenta: "99",
			tipoCuentaUso: "P",
		});
	}

	
	/* Si el usuario seleccionó Cuenta Bancaria como forma de pago secundaria */
	if (values.radioButtonS === "0") { 
		arrCuentas.push({
			institucion: values.institucionS,
			numeroCuenta: values.numeroCuentaS,
			radioButton: values.radioButtonS,
			rut: values.rutS,
			tipoCuenta: values.tipoCuentaS,
			tipoCuentaUso: "S",
		});
		
	} 
	/* El usuario seleccionó Vale Vista como forma de pago secundaria */
	else {
		arrCuentas.push({
			institucion: "99",
			numeroCuenta: "",
			radioButton: values.radioButtonS,
			rut: "",
			tipoCuenta: "99",
			tipoCuentaUso: "S",
		});
	}
	
	console.log(arrCuentas);

	changeLoading(true);

	let aux_tipo_cuenta = "";
	arrCuentas.forEach((cuenta, index) => {

		console.info(cuenta);

		switch (cuenta.tipoCuenta) {
			case "1":
				aux_tipo_cuenta = "B";
				break;
			case "2":
				aux_tipo_cuenta = "A";
				break;
			case "3":
				aux_tipo_cuenta = "P";
				break;
			case "4":
				aux_tipo_cuenta = "V";
				break;

			default:
				aux_tipo_cuenta = "";
				break;
		}

		const body = {
			index: 0,
			idPersona: idPersona,
			idCliente: idCliente,
			codInstFinancDeposito:
				cuenta.radioButton === "0"
					? Number.parseInt(cuenta.institucion, 10)
					: 0,
			codInstFinancEmisorPago: codInstFinancEmisorPago,
			codPaisReceptor: codPaisReceptor,
			codRegionReceptor: 0,
			idReceptorPago:
				cuenta.radioButton === "0"
					? convertirRutAId(cleanRut(cuenta.rut))
					: idPersona,
			numCuentaDeposito: cuenta.radioButton === "0" ? cuenta.numeroCuenta : "",
			tipoCuentaDeposito: cuenta.radioButton === "0" ? aux_tipo_cuenta : "",
			tipoReceptor: informante,
			tipoViaPago: cuenta.radioButton === "0" ? "D" : "W",
			tipoCuentaUso: cuenta.tipoCuentaUso,
		};

		axios
			.get(
				`${configuration.API_URL}/FormaPago/ObtenerFormasPagoCliente` +
					"?idCliente=" +
					body.idCliente +
					"&tipoCuentaUso=" +
					body.tipoCuentaUso,
				{
					headers: configuration.JSON_HEADER,
				}
			)
			.then((response) => {
				console.info("response.data.resultado Obtener Pagos");
				let respuesta = "OK";

				if (response.status === 200) {
					if (response.data.resultado === null) {
						axios
							.post(
								`${configuration.API_URL}/FormaPago/IngresarFormaPago`,
								body,
								{ headers: configuration.JSON_HEADER }
							)
							.then((response) => {
								if (response.status !== 200) {
									console.info("NOK");
									changeLoading(false);
								} else {
									console.info("OK");
									if (index === cantidadCuentas) {
										window.location.href = "/resumen";
									}
								}
							})
							.catch((e) => {
								console.error(e);
							});
					} else {
						body.index = response.data.resultado.index;
						axios
							.put(
								`${configuration.API_URL}/FormaPago/ActualizarFormaPago`,
								body,
								{ headers: configuration.JSON_HEADER }
							)
							.then((response) => {
								console.info("response Actualizar Forma Pagos");
								if (response.status !== 200) {
									console.info("NOK");
									changeLoading(false);
								} else {
									console.info("OK");
									if (index === cantidadCuentas) {
										window.location.href = "/resumen";
									}
								}
							})
							.catch((e) => {
								console.error(e);
							});
					}
				}

				return respuesta;
			})
			.catch((e) => {
				console.error(e);
			});
	});
};
