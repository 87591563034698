import { useFormikContext } from "formik";
import { useEffect } from "react";
import configuration from "../../Configuration";
import axios from "axios";
import { clearPreloadedDate } from "../../utils/date";
import { formatCelular } from "../../utils/helpers";
import { clearInformacionMandatario } from "../../services/api/solicitud/index";
import Swal from "sweetalert2";

const PreDataFormAfiliados = () => {
  const { setTouched, setValues } = useFormikContext();
  useEffect(() => {
    const idCliente = localStorage.getItem("idPersona");
    const obtenerDatosAfiliados = async () => {
      // Limpiar información del mandatario en caso de que hubiera
      const mandatario = localStorage.getItem("informante"); // A=Afiliado, M=Mandatario set at utils/handleSubmit/formNumeroSerie.js line 47
      if (mandatario !== "M") {
        await clearInformacionMandatario(idCliente);
      }

      const urlObtenerSolicitud = `${configuration.API_URL}/Solicitud/obtenerSolicitud?rut=${idCliente}`;

      await axios
        .get(urlObtenerSolicitud)
        .then((response) => {
          const data = response.data.resultado;

          localStorage.setItem("folioSolicitud", data.folioSolicitud);
          // const fechaCreaction = data.createDate;

          // Swal.fire({
          // 	title: "Tienes datos guardados de solicitudes anteriores",
          // 	text: "¿Deseas cargar la información almacenada en nuestros registros?",
          // 	icon: "info",
          // 	showCancelButton: true,
          // 	confirmButtonColor: "#72B500",
          // 	cancelButtonColor: "#d33",
          // 	confirmButtonText: "Si, cargar información guardada.",
          // 	cancelButtonText: `No, deseo ingresar los datos nuevamente.`,
          // 	allowOutsideClick: false,
          // 	reverseButtons: true,
          // }).then((result) => {
          // 	console.log(result);
          // 	if (result.isConfirmed) {

          // 	}
          // });

          let fecha =
            data.fecNacAfi === null ? "" : clearPreloadedDate(data.fecNacAfi);

          const formAfiliado = {
            nombres: data.nombreAfi === null ? "" : data.nombreAfi,
            apellidoPaterno:
              data.apPaternoAfi === null ? "" : data.apPaternoAfi,
            apellidoMaterno:
              data.apMaternoAfi === null ? "" : data.apMaternoAfi,
            fechaNacimiento: fecha,
            genero: data.generoAfi === null ? "" : data.generoAfi,
            direccion: data.nombreCalleAfi === null ? "" : data.nombreCalleAfi,
            numero: data.numeroAfi === null ? "" : data.numeroAfi,
            depto: data.numeroDeptoAfi === null ? "" : data.numeroDeptoAfi,
            villa:
              data.poblacionVillaAfi === null ? "" : data.poblacionVillaAfi,
            region:
              data.codRegionAfi === null || data.codRegionAfi === ""
                ? "99"
                : data.codRegionAfi,
            ciudad:
              data.ciudadAfi === null || data.ciudadAfi === ""
                ? "99"
                : data.ciudadAfi,
            comuna:
              data.codComunaAfi === null || data.codComunaAfi === ""
                ? "99"
                : data.codComunaAfi,
            telefonoPrincipal:
              data.celularAfiPrincipal === null
                ? ""
                : formatCelular(data.celularAfiPrincipal.substr(2, 9).trim()),
            telefonoConfirmar:
              data.celularAfiPrincipal === null
                ? ""
                : formatCelular(data.celularAfiPrincipal.substr(2, 9).trim()),
            telefonoSecundario:
              data.celularAfiOpcional === null
                ? ""
                : formatCelular(data.celularAfiOpcional.substr(2, 9).trim()),
            correo: data.emailAfi === null ? "" : data.emailAfi,
            correoConfirmar: "",
            prevision:
              data.codInstitucionSalud === null ? "" : data.codInstitucionSalud,
            certificadoMedico: false,
            certificadoGES: false,
            calificacionInvalidez: false,
            solicitudAPS:
              data.indAutorizaSusAps === false ? "" : data.indAutorizaSusAps,
            checkCorreo: false,
            lugarReposo: "",
            recintoHospitalario: "",
            direccionReposo: "",
            numeroReposo: "",
            deptoReposo: "",
            villaReposo: "",
            regionReposo: "99",
            ciudadReposo: "99",
            comunaReposo: "99",
            nivelEducacional: "",
            profesion: "",
            antecedentesLaborales: "",
            fechaInicioDesempleoPrivado: "01/01/2000",
            fechaInicioDesempleoPublico: "01/01/2000",
            fechaInicioLicenciaMedica: "01/01/2000",
            fechaFinLicenciaMedica: "01/01/2000",
            afiliadoPensionado: "",
          };

          const formAfiliado_touched = {
            nombres: true,
            apellidoPaterno: true,
            apellidoMaterno: true,
            fechaNacimiento: true,
            genero: true,
            direccion: true,
            numero: true,
            depto: true,
            villa: true,
            region: true,
            ciudad: true,
            comuna: true,
            telefonoPrincipal: true,
            telefonoConfirmar: "",
            telefonoSecundario: true,
            correo: "",
            correoConfirmar: "",
            prevision: true,
            certificadoMedico: false,
            certificadoGES: false,
            calificacionInvalidez: false,
            solicitudAPS: false,
          };

          setValues(formAfiliado, true);
          setTouched(formAfiliado_touched);
        })
        .catch((e) => {
          console.error(e);
        });
    };

    obtenerDatosAfiliados();
  }, []);

  return null;
};

export default PreDataFormAfiliados;
