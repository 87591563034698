import React, { useEffect, useState } from "react";
import axios from "axios";
import { Formik, Form, Field, useFormikContext } from "formik";
import { validationSchemaDatosAfiliados } from "../utils/validationSchemaForm";
import MaskedInput from "react-text-mask";
import { removeCodigoArea, cleanDigitos } from "../utils/cleanInputMask";
import { convertDate, getToday } from "../utils/date";
import { celularMaskNew, fechaMask } from "../utils/inputMask";
import { eacento, oacento, uacento } from "../utils/caracteresUTF8";
import configuration from "../Configuration";
import { useHistory } from "react-router-dom";
import { obtenerComunas, selectComuna } from "../services/api/comuna/index";
import { obtenerInstituciones } from "../services/api/institucionSalud/index";
import { obtenerCondicionLaboral } from "../services/api/condicionLaboral";
import { obtenerParametrosReposo } from "../services/api/reposo";
import PreDataFormAfiliados from "./FormPreData/formAfiliados";
import { initialValuesFormAfiliados } from "../utils/initialValues/formAfiliados";
import { obtenerOcupacionuOficio } from "../services/api/ocupacion";
import { obtenerNivelEducacional } from "../services/api/nivelEducacional";
import { obtenerTipoAfiliadoPensionado } from "../services/api/tipoAfiliado";
import { genericPost } from "../utils/genericApiCall";
import Stack from "../components/commons/stack/Stack";

const FormDatosAfiliados = () => {
  let history = useHistory();

  const [modalShow, setModalShow] = useState(false);
  const handleShow = () => setModalShow(true);
  const handleClose = () => setModalShow(false);
  const [isLoading, setIsLoading] = useState(false);
  const [regiones, setRegiones] = useState([]);
  const [ciudades, setCiudades] = useState([]);
  const [comunas, setComunas] = useState([]);
  const [regionesReposo, setRegionesReposo] = useState([]);
  const [ciudadesReposo, setCiudadesReposo] = useState([]);
  const [comunasReposo, setComunasReposo] = useState([]);
  const [previsiones, setPrevisiones] = useState([]);
  const [region, setRegion] = useState("");
  const [ciudad, setCiudad] = useState("");
  const [comuna, setComuna] = useState("");
  const [regionReposo, setRegionReposo] = useState("");
  const [ciudadReposo, setCiudadReposo] = useState("");
  const [esBeneficiario, setEsBeneficiario] = useState("");
  const [TipoAfiliado, setTipoAfiliado] = useState();
  const [comunaReposo, setComunaReposo] = useState(false);
  const [condicionesLaborales, setCondicionesLaborales] = useState([]);
  const [parametrosReposo, setParametrosReposo] = useState([]);
  const [parametrosOcupacion, setParametrosOcupacion] = useState([]);
  const [parametrosEducacion, setParametrosEducacion] = useState([]);
  const [parametrosAfiliadoPensionado, setParametrosAfiliadoPensionado] =
    useState([]);

  async function selectComuna(event, formik) {
    let selected = event.target.value;
    formik.setFieldValue("comuna", selected);
    setComuna(selected);
  }

  async function selectComunaReposo(event, formik) {
    let selected = event.target.value;
    formik.setFieldValue("comunaReposo", selected);
    setComunaReposo(selected);
  }

  async function selectCiudad(event, formik) {
    var value = event.target.value;

    formik.setFieldValue("ciudad", value);
    setCiudad(value);

    formik.setFieldValue("comuna", "99");
    setComunas([]);

    if (value !== "99" || value !== "Seleccionar" || value !== "") {
      var data = value.split("-");
      let json = await ciudades.find((x) => x.codigo === data[0]);

      if (json !== undefined) {
        setComunas(json["comunas"]);
      }
    }
  }

  async function selectCiudadReposo(event, formik) {
    var value = event.target.value;

    formik.setFieldValue("ciudadReposo", value);
    setCiudadReposo(value);

    formik.setFieldValue("comunaReposo", "99");
    setComunasReposo([]);

    if (value !== "99" || value !== "Seleccionar" || value !== "") {
      var data = value.split("-");
      let json = await ciudadesReposo.find((x) => x.codigo === data[0]);

      if (json !== undefined) {
        setComunasReposo(json["comunas"]);
      }
    }
  }

  async function selectRegion(event, formik) {
    let selected = event.target.value;

    formik.setFieldValue("region", selected);
    setRegion(selected);

    formik.setFieldValue("ciudad", "99");
    setCiudades([]);

    formik.setFieldValue("comuna", "99");
    setComunas([]);

    if (selected !== "99" || selected !== "") {
      let json = await regiones.find((x) => x.codigo === selected);

      if (json !== undefined) {
        setCiudades(json["provincias"]);
      }
    }
  }

  async function selectRegionReposo(event, formik) {
    let selected = event.target.value;

    formik.setFieldValue("regionReposo", selected);
    setRegionReposo(selected);

    formik.setFieldValue("ciudadReposo", "99");
    setCiudadesReposo([]);

    formik.setFieldValue("comunaReposo", "99");
    setComunasReposo([]);

    if (selected !== "99" || selected !== "") {
      let json = await regiones.find((x) => x.codigo === selected);

      if (json !== undefined) {
        setCiudadesReposo(json["provincias"]);
      }
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [
          comunasResponse,
          institucionesSaludResponse,
          condicionesLaboralesResponse,
          parametrosReposoResponse,
          parametrosOcupacionesResponse,
          parametrosEducacionResponse,
          parametrosAfiliadoPensionadoResponse,
        ] = await Promise.all([
          obtenerComunas(),
          obtenerInstituciones(),
          obtenerCondicionLaboral(),
          obtenerParametrosReposo(),
          obtenerOcupacionuOficio(),
          obtenerNivelEducacional(),
          obtenerTipoAfiliadoPensionado(),
        ]);

        if (comunasResponse.status === 200) {
          setRegiones(comunasResponse.data);
          setRegionesReposo(comunasResponse.data);
        }

        if (institucionesSaludResponse.status === 200) {
          const data = institucionesSaludResponse.data;

          if (data.ok) {
            const previ = data.resultado.map((datos) => ({
              codigo: datos.idInstSalud,
              nombre: datos.descripcion,
            }));
            setPrevisiones(previ);
          }
        }

        if (condicionesLaboralesResponse.status === 200) {
          setCondicionesLaborales(condicionesLaboralesResponse.data.resultado);
        }

        if (parametrosReposoResponse.status === 200) {
          setParametrosReposo(parametrosReposoResponse.data);
        }

        if (parametrosOcupacionesResponse.status === 200) {
          setParametrosOcupacion(parametrosOcupacionesResponse.data.resultado);
        }

        if (parametrosEducacionResponse.status === 200) {
          setParametrosEducacion(parametrosEducacionResponse.data);
        }

        if (parametrosAfiliadoPensionadoResponse.status === 200) {
          setParametrosAfiliadoPensionado(
            parametrosAfiliadoPensionadoResponse.data
          );
        }
        setTipoAfiliado(localStorage.getItem("informante"));
        setEsBeneficiario(
          localStorage.getItem("esBeneficiario") === "true" ? "Si" : "No"
        );
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);
  /*useEffect(async () => {
		const obtener_comunas = await obtenerComunas();
		if (obtener_comunas.status === 200) {
			setRegiones(obtener_comunas.data);
			setRegionesReposo(obtener_comunas.data);
		}
		const obtener_instituciones_salud = await obtenerInstituciones();
		let data = obtener_instituciones_salud.data;

		if (obtener_instituciones_salud.status === 200) {
			if (data.ok) {
				var previ = new Array(2);
				var codigo = "";
				var nombre = "";
				data.resultado.map(
					(datos, i) => (
						(codigo = datos.idInstSalud),
						(nombre = datos.descripcion),
						(previ[i] = [codigo, nombre])
					)
				);
			}
			setPrevisiones(previ);
		}

		const obtener_condiciones_laborales = await obtenerCondicionLaboral();

		if (obtener_condiciones_laborales.status === 200) {
			setCondicionesLaborales(obtener_condiciones_laborales.data.resultado);
		}

		const obtener_parametros_reposo = await obtenerParametrosReposo();

		if (obtener_parametros_reposo.status === 200) {
			setParametrosReposo(obtener_parametros_reposo.data);
		}

		const obtener_parametros_ocupaciones = await obtenerOcupacionuOficio();

		if (obtener_parametros_ocupaciones.status === 200) {
			setParametrosOcupacion(obtener_parametros_ocupaciones.data.resultado);
		}

		const obtener_parametros_educacion = await obtenerNivelEducacional();

		if (obtener_parametros_educacion.status === 200) {
			setParametrosEducacion(obtener_parametros_educacion.data);
		}

		const obtener_parametros_afiliado_pensionado =
			await obtenerTipoAfiliadoPensionado();

		if (obtener_parametros_afiliado_pensionado.status === 200) {
			setParametrosAfiliadoPensionado(
				obtener_parametros_afiliado_pensionado.data
			);
		}
	}, []);*/

  const handleSubmit = (values) => {
    setIsLoading(true);
    let nuevaFecha = convertDate(values.fechaNacimiento);

    axios
      .get(
        `${
          configuration.API_URL
        }/Solicitud/obtenerSolicitud?rut=${localStorage.getItem("idPersona")}`,
        { headers: configuration.JSON_HEADER }
      )
      .then((response) => {
        let data = response.data;

        if (response.status === 200) {
          let body = data.resultado;

          body.CodEstado = 10;
          body.nombreAfi = values.nombres;
          body.apPaternoAfi = values.apellidoPaterno;
          body.apMaternoAfi = values.apellidoMaterno;
          body.fecNacAfi = nuevaFecha;
          body.generoAfi = values.genero;
          body.nombreCalleAfi = values.direccion;
          body.numeroAfi = values.numero;
          body.numeroDeptoAfi = values.depto;
          body.poblacionVillaAfi = values.villa;
          body.codRegionAfi = region;

          var newCiudad = ciudad.split("-");
          body.ciudadAfi = newCiudad[1];
          body.codComunaAfi = comuna;

          body.celularAfiPrincipal = removeCodigoArea(
            cleanDigitos(values.telefonoPrincipal)
          );
          body.celularAfiOpcional = removeCodigoArea(
            cleanDigitos(values.telefonoSecundario)
          );

          body.emailAfi = values.correo;
          body.codInstitucionSalud = parseInt(values.prevision);
          body.indAutorizaSusCalificacion = "S"; // 20211130 MODELO INDICA QUE SIEMPRE DEBE IR EN 'S' values.calificacionInvalidez ? "S" : "N";

          let body_anexo = {};

          body_anexo.idNumeroFolio = data.resultado.folioSolicitud;
          body_anexo.idTipAfiPen = parseInt(values.afiliadoPensionado);
          body_anexo.idRepPen = parseInt(values.lugarReposo);
          body_anexo.idConAfi = parseInt(values.antecedentesLaborales);
          body_anexo.idNivEduAfi = parseInt(values.nivelEducacional);
          body_anexo.idProOfiAfi = parseInt(values.profesion);
          body_anexo.calle = values.direccionReposo;
          body_anexo.numero = values.numeroReposo;
          body_anexo.departamentoCasa = values.deptoReposo;
          body_anexo.villaPoblacion = values.villaReposo;
          body_anexo.codRegion = parseInt(values.regionReposo);

          var newCiudadReposo = values.ciudadReposo.split("-");
          body_anexo.ciudad = newCiudadReposo[1];
          body_anexo.codComuna = parseInt(values.comunaReposo);
          body_anexo.nombreRecintoHospilatario = values.recintoHospitalario;
          body_anexo.fecIniDes = convertDate(
            values.fechaInicioDesempleoPrivado
          );
          body_anexo.fecCeseFunciones = convertDate(
            values.fechaInicioDesempleoPublico
          );
          body_anexo.fecIniLicMed = convertDate(
            values.fechaInicioLicenciaMedica
          );
          body_anexo.fecTerLicMed = convertDate(values.fechaFinLicenciaMedica);

          let bodyAutoriza = {
            folioSolicitud: data.resultado.folioSolicitud,
            idPersona: localStorage.getItem("idPersona"),
            respuesta: values.Autoriza ? "Si" : "No",
            tipoInformante: TipoAfiliado,
            beneficiario: esBeneficiario,
            cobertura: values.radioCobertura,
          };

          genericPost({
            url_endpoint: "/Solicitud/autorizaBenficiario",
            body: bodyAutoriza,
            message_info: "Datos autorización",
            message_error: "error al grabar autorización",
          });

          axios
            .put(
              `${configuration.API_URL}/Solicitud/actualizarSolicitud`,
              body,
              { headers: configuration.JSON_HEADER }
            )
            .then((response) => {
              let data = response.data;

              if (response.status === 200) {
                if (data.ok) {
                  axios
                    .get(
                      `${configuration.API_URL}/OpAfiliadosAnexo/ObtenerAnexoRetiroAfiliadoPorFolio?idFolio=${body_anexo.idNumeroFolio}`
                    )
                    .then((response) => {
                      if (
                        response.status === 200 &&
                        response.data.ok === false
                      ) {
                        axios
                          .post(
                            `${configuration.API_URL}/OpAfiliadosAnexo/IngresarDatosRetiroAfiliadoAnexo`,
                            body_anexo,
                            { headers: configuration.JSON_HEADER }
                          )
                          .then((response) => {
                            if (response.status === 200 && response.data.ok) {
                              history.push("/documentos");
                              setIsLoading(false);
                            } else {
                              console.info("NOT_OK");
                            }
                          })
                          .catch((e) => {
                            console.error(
                              "/OpAfiliadosAnexo/IngresarDatosRetiroAfiliadoAnexo: ",
                              e
                            );
                          });
                      }

                      if (response.status === 200 && response.data.ok) {
                        axios
                          .put(
                            `${configuration.API_URL}/OpAfiliadosAnexo/ActualizarDatosRetiroAfiliadoAnexo`,
                            body_anexo,
                            { headers: configuration.JSON_HEADER }
                          )
                          .then((response) => {
                            if (response.status === 200 && response.data.ok) {
                              history.push("/documentos");
                              setIsLoading(false);
                            } else {
                              console.info("NOT_OK");
                            }
                          })
                          .catch((e) => {
                            console.error(
                              "/OpAfiliadosAnexo/ActualizarDatosRetiroAfiliadoAnexo: ",
                              e
                            );
                          });
                      }
                    });
                } else {
                  console.info("NOK");
                  setIsLoading(false);
                }
              } else if (!data.resultado) {
              }
            })
            .catch((e) => {
              console.error("/Solicitud/actualizarSolicitud: ", e);
            });
        }
      })
      .catch((e) => {
        console.error("Servicio Solicitud: ", e);
      });
    setIsLoading(false);
  };

  return (
    <Formik
      initialValues={initialValuesFormAfiliados}
      validationSchema={validationSchemaDatosAfiliados}
      onSubmit={handleSubmit}
    >
      {(formik) => (
        <Form>
          <PreDataFormAfiliados />
          <span>Nombres</span>
          <Field name="nombres">
            {({ field, meta }) => (
              <div className="form-group input-wrapper">
                <input
                  {...field}
                  type="text"
                  className={`form-control form-control-lg ${
                    meta.touched ? (meta.error ? "is-invalid" : "is-valid") : ""
                  }`}
                  id="nombres"
                  name="nombres"
                  placeholder=""
                  autoComplete="off"
                  aria-describedby="nombresAyuda"
                />
                <label htmlFor="nombres" className="control-label">
                  Nombres
                </label>
                <small
                  id="nombresAyuda"
                  className={`form-text ${
                    meta.touched && meta.error ? "is-invalid" : ""
                  }`}
                >
                  {" "}
                  {meta.touched && meta.error
                    ? meta.error
                    : "Indícanos tu nombre o si tienes más de uno. Ej: Ana Antonia."}
                </small>
              </div>
            )}
          </Field>
          <span>Apellido Paterno</span>
          <Field name="apellidoPaterno">
            {({ field, meta }) => (
              <div className="form-group input-wrapper">
                <input
                  {...field}
                  type="text"
                  className={`form-control form-control-lg ${
                    meta.touched ? (meta.error ? "is-invalid" : "is-valid") : ""
                  }`}
                  id="apellidoPaterno"
                  name="apellidoPaterno"
                  placeholder=""
                  autoComplete="off"
                  aria-describedby="apellidoPaternoAyuda"
                />
                <label htmlFor="apellidoPaterno" className="control-label">
                  Apellido Paterno
                </label>
                <small
                  id="apellidoPaternoAyuda"
                  className={`form-text ${
                    meta.touched && meta.error ? "is-invalid" : ""
                  }`}
                >
                  {" "}
                  {meta.touched && meta.error
                    ? meta.error
                    : "Indícanos tu apellido paterno. Ej: Soto."}
                </small>
              </div>
            )}
          </Field>
          <span>Apellido Materno (opcional)</span>
          <Field name="apellidoMaterno">
            {({ field, meta }) => (
              <div className="form-group input-wrapper">
                <input
                  {...field}
                  type="text"
                  className={`form-control form-control-lg ${
                    meta.touched ? (meta.error ? "is-invalid" : "is-valid") : ""
                  }`}
                  id="apellidoMaterno"
                  name="apellidoMaterno"
                  placeholder=""
                  autoComplete="off"
                  aria-describedby="apellidoMaternoAyuda"
                />
                <label htmlFor="apellidoMaterno" className="control-label">
                  Apellido Materno (opcional)
                </label>
                <small
                  id="apellidoMaternoAyuda"
                  className={`form-text ${
                    meta.touched && meta.error ? "is-invalid" : ""
                  }`}
                >
                  {" "}
                  {meta.touched && meta.error
                    ? meta.error
                    : "Indícanos tu apellido materno. Ej: Modelo."}
                </small>
              </div>
            )}
          </Field>
          <span>Fecha de nacimiento</span>
          <Field name="fechaNacimiento">
            {({ field, meta }) => (
              <div className="form-group input-wrapper">
                <MaskedInput
                  {...field}
                  mask={fechaMask}
                  type="text"
                  className={`form-control form-control-lg ${
                    meta.touched ? (meta.error ? "is-invalid" : "is-valid") : ""
                  }`}
                  id="fechaNacimiento"
                  name="fechaNacimiento"
                  placeholder="dd/mm/yyyy"
                  autoComplete="off"
                  aria-describedby="fechaNacimientoAyuda"
                />
                <label htmlFor="fechaNacimiento" className="control-label">
                  Fecha de nacimiento
                </label>
                <small
                  id="fechaNacimientoAyuda"
                  className={`form-text ${
                    meta.touched && meta.error ? "is-invalid" : ""
                  }`}
                >
                  {" "}
                  {meta.touched && meta.error ? meta.error : "Ej: 01/12/1991."}
                </small>
              </div>
            )}
          </Field>
          <Field name="genero">
            {({ field, meta }) => (
              <div className="form-group input-wrapper select">
                <label htmlFor="genero">
                  <span>Indique su género:</span>
                  <select
                    {...field}
                    className="form-control form-control-lg"
                    id="genero"
                    name="genero"
                  >
                    {" "}
                    {/* eslint-disable-line */}
                    <option value="99">Seleccionar</option>
                    <option value="F">Femenino</option>
                    <option value="M">Masculino</option>
                  </select>
                </label>
                <small
                  id="generoAyuda"
                  className={`form-text ${
                    meta.touched ? (meta.error ? "is-invalid" : "is-valid") : ""
                  }`}
                >
                  {meta.touched && meta.error ? meta.error : ""}
                </small>
              </div>
            )}
          </Field>
          <span>Direcci{oacento}n</span>
          <Field name="direccion">
            {({ field, meta }) => (
              <div className="form-group input-wrapper">
                <input
                  {...field}
                  type="text"
                  className={`form-control form-control-lg ${
                    meta.touched ? (meta.error ? "is-invalid" : "is-valid") : ""
                  }`}
                  id="direccion"
                  name="direccion"
                  placeholder=""
                  autoComplete="off"
                  aria-describedby="direccionAyuda"
                />
                <label htmlFor="direccion" className="control-label">
                  Direcci{oacento}n
                </label>
                <small
                  id="direccionAyuda"
                  className={`form-text ${
                    meta.touched && meta.error ? "is-invalid" : ""
                  }`}
                >
                  {" "}
                  {meta.touched && meta.error
                    ? meta.error
                    : "Ej: Avenida Modelo"}
                </small>
              </div>
            )}
          </Field>
          <span>N{uacento}mero</span>
          <Field name="numero">
            {({ field, meta }) => (
              <div className="form-group input-wrapper">
                <input
                  {...field}
                  type="text"
                  className={`form-control form-control-lg ${
                    meta.touched ? (meta.error ? "is-invalid" : "is-valid") : ""
                  }`}
                  id="numero"
                  name="numero"
                  placeholder=""
                  autoComplete="off"
                  aria-describedby="numeroAyuda"
                />
                <label htmlFor="numero" className="control-label">
                  N{uacento}mero
                </label>
                <small
                  id="numeroAyuda"
                  className={`form-text ${
                    meta.touched && meta.error ? "is-invalid" : ""
                  }`}
                >
                  {" "}
                  {meta.touched && meta.error ? meta.error : "Ej: #2021"}
                </small>
              </div>
            )}
          </Field>
          <span>N° de departamento/ Casa (opcional)</span>
          <Field name="depto">
            {({ field, meta }) => (
              <div className="form-group input-wrapper">
                <input
                  {...field}
                  type="text"
                  className={`form-control form-control-lg ${
                    meta.touched ? (meta.error ? "is-invalid" : "is-valid") : ""
                  }`}
                  id="depto"
                  name="depto"
                  placeholder=""
                  autoComplete="off"
                  aria-describedby="deptoAyuda"
                />
                <label htmlFor="depto" className="control-label">
                  N° de departamento/ Casa (opcional)
                </label>
                <small
                  id="deptoAyuda"
                  className={`form-text ${
                    meta.touched && meta.error ? "is-invalid" : ""
                  }`}
                >
                  {" "}
                  {meta.touched && meta.error ? meta.error : "Ej: B22"}
                </small>
              </div>
            )}
          </Field>
          <span>Villa o Poblaci{oacento}n (opcional)</span>
          <Field name="villa">
            {({ field, meta }) => (
              <div className="form-group input-wrapper">
                <input
                  {...field}
                  type="text"
                  className={`form-control form-control-lg ${
                    meta.touched ? (meta.error ? "is-invalid" : "is-valid") : ""
                  }`}
                  id="villa"
                  name="villa"
                  placeholder=""
                  autoComplete="off"
                  aria-describedby="villaAyuda"
                />
                <label htmlFor="villa" className="control-label">
                  Villa o Poblaci{oacento}n *
                </label>
                <small
                  id="villaAyuda"
                  className={`form-text ${
                    meta.touched && meta.error ? "is-invalid" : ""
                  }`}
                >
                  {" "}
                  {meta.touched && meta.error
                    ? meta.error
                    : "Ej: Villa el Porvenir."}
                </small>
              </div>
            )}
          </Field>
          <Field name="region">
            {({ field, meta }) => (
              <div className="form-group input-wrapper select">
                <label htmlFor="region">
                  Regi{oacento}n
                  <select
                    {...field}
                    className="form-control form-control-lg"
                    id="region"
                    name="region"
                    value={region}
                    onChange={(e) => {
                      selectRegion(e, formik);
                    }}
                  >
                    <option value="99">Seleccionar</option>
                    {regiones.map((datos) => (
                      <option value={datos.codigo}>{datos.region}</option>
                    ))}
                  </select>
                </label>
                <small
                  id="regionAyuda"
                  className={`form-text ${
                    meta.touched ? (meta.error ? "is-invalid" : "is-valid") : ""
                  }`}
                >
                  {meta.touched && meta.error ? meta.error : ""}
                </small>
              </div>
            )}
          </Field>
          <Field name="ciudad">
            {({ field, meta }) => (
              <div className="form-group input-wrapper select">
                <label htmlFor="ciudad">
                  Ciudad
                  <select
                    {...field}
                    className="form-control form-control-lg"
                    id="ciudad"
                    name="ciudad"
                    value={ciudad}
                    onChange={(e) => {
                      selectCiudad(e, formik);
                    }}
                  >
                    <option value="99">Seleccionar</option>
                    {ciudades.map((datos) => (
                      <option value={`${datos.codigo}-${datos.provincia}`}>
                        {datos.provincia}
                      </option>
                    ))}
                  </select>
                </label>
                <small
                  id="ciudadAyuda"
                  className={`form-text ${
                    meta.touched ? (meta.error ? "is-invalid" : "is-valid") : ""
                  }`}
                >
                  {meta.touched && meta.error ? meta.error : ""}
                </small>
              </div>
            )}
          </Field>
          <Field name="comuna">
            {({ field, meta }) => (
              <div className="form-group input-wrapper select">
                <label htmlFor="comuna">
                  Comuna
                  <select
                    {...field}
                    className="form-control form-control-lg"
                    id="comuna"
                    name="comuna"
                    value={comuna}
                    onChange={(e) => {
                      selectComuna(e, formik);
                    }}
                  >
                    {" "}
                    {/* eslint-disable-line */}
                    <option value="99">Seleccionar</option>
                    {comunas.map((datos) => (
                      <option value={datos.codigo}>{datos.comuna}</option>
                    ))}
                  </select>
                </label>
                <small
                  id="comunaAyuda"
                  className={`form-text ${
                    meta.touched ? (meta.error ? "is-invalid" : "is-valid") : ""
                  }`}
                >
                  {meta.touched && meta.error ? meta.error : ""}
                </small>
              </div>
            )}
          </Field>

          <p>Lugar donde cumple reposo</p>

          <Field name="lugarReposo">
            {({ field, meta }) => (
              <div className="form-group input-wrapper select">
                <label htmlFor="lugarReposo">
                  Indique donde cumple reposo actualmente:
                  <select
                    {...field}
                    className="form-control form-control-lg"
                    id="lugarReposo"
                    name="lugarReposo"
                  >
                    {" "}
                    {/* eslint-disable-line */}
                    <option value="99">Seleccionar</option>
                    {parametrosReposo.map((datos) => (
                      <option value={datos.idRepPen}>
                        {datos.descripcion}
                      </option>
                    ))}
                  </select>
                </label>
                <small
                  id="lugarReposoAyuda"
                  className={`form-text ${
                    meta.touched ? (meta.error ? "is-invalid" : "is-valid") : ""
                  }`}
                >
                  {meta.touched && meta.error ? meta.error : ""}
                </small>
              </div>
            )}
          </Field>

          {formik.values.lugarReposo === "2" && (
            <>
              <span>Nombre del recinto hospitalario</span>
              <Field name="recintoHospitalario">
                {({ field, meta }) => (
                  <div className="form-group input-wrapper">
                    <input
                      {...field}
                      type="text"
                      className={`form-control form-control-lg ${
                        meta.touched
                          ? meta.error
                            ? "is-invalid"
                            : "is-valid"
                          : ""
                      }`}
                      id="recintoHospitalario"
                      name="recintoHospitalario"
                      placeholder=""
                      autoComplete="off"
                      aria-describedby="recintoHospitalarioAyuda"
                    />
                    <label
                      htmlFor="recintoHospitalario"
                      className="control-label"
                    >
                      Ej: Hospital Barros Luco
                    </label>
                    <small
                      id="recintoHospitalarioAyuda"
                      className={`form-text ${
                        meta.touched && meta.error ? "is-invalid" : ""
                      }`}
                    >
                      {" "}
                      {meta.touched && meta.error
                        ? meta.error
                        : "Ej: Hospital Barros Luco"}
                    </small>
                  </div>
                )}
              </Field>
            </>
          )}

          {formik.values.lugarReposo === "3" && (
            <>
              <span>Direcci{oacento}n</span>
              <Field name="direccionReposo">
                {({ field, meta }) => (
                  <div className="form-group input-wrapper">
                    <input
                      {...field}
                      type="text"
                      className={`form-control form-control-lg ${
                        meta.touched
                          ? meta.error
                            ? "is-invalid"
                            : "is-valid"
                          : ""
                      }`}
                      id="direccionReposo"
                      name="direccionReposo"
                      placeholder=""
                      autoComplete="off"
                      aria-describedby="direccionReposoAyuda"
                    />
                    <label htmlFor="direccionReposo" className="control-label">
                      Direcci{oacento}n
                    </label>
                    <small
                      id="direccionReposoAyuda"
                      className={`form-text ${
                        meta.touched && meta.error ? "is-invalid" : ""
                      }`}
                    >
                      {" "}
                      {meta.touched && meta.error
                        ? meta.error
                        : "Ej: Avenida Modelo"}
                    </small>
                  </div>
                )}
              </Field>
              <span>N{uacento}mero</span>
              <Field name="numeroReposo">
                {({ field, meta }) => (
                  <div className="form-group input-wrapper">
                    <input
                      {...field}
                      type="text"
                      className={`form-control form-control-lg ${
                        meta.touched
                          ? meta.error
                            ? "is-invalid"
                            : "is-valid"
                          : ""
                      }`}
                      id="numeroReposo"
                      name="numeroReposo"
                      placeholder=""
                      autoComplete="off"
                      aria-describedby="numeroReposoAyuda"
                    />
                    <label htmlFor="numeroReposo" className="control-label">
                      N{uacento}mero
                    </label>
                    <small
                      id="numeroReposoAyuda"
                      className={`form-text ${
                        meta.touched && meta.error ? "is-invalid" : ""
                      }`}
                    >
                      {" "}
                      {meta.touched && meta.error ? meta.error : "Ej: #2021"}
                    </small>
                  </div>
                )}
              </Field>
              <span>N° de departamento/ Casa (opcional)</span>
              <Field name="deptoReposo">
                {({ field, meta }) => (
                  <div className="form-group input-wrapper">
                    <input
                      {...field}
                      type="text"
                      className={`form-control form-control-lg ${
                        meta.touched
                          ? meta.error
                            ? "is-invalid"
                            : "is-valid"
                          : ""
                      }`}
                      id="deptoReposo"
                      name="deptoReposo"
                      placeholder=""
                      autoComplete="off"
                      aria-describedby="deptoReposoAyuda"
                    />
                    <label htmlFor="deptoReposo" className="control-label">
                      N° de departamento/ Casa (opcional)
                    </label>
                    <small
                      id="deptoReposoAyuda"
                      className={`form-text ${
                        meta.touched && meta.error ? "is-invalid" : ""
                      }`}
                    >
                      {" "}
                      {meta.touched && meta.error ? meta.error : "Ej: B22"}
                    </small>
                  </div>
                )}
              </Field>

              <span>Villa o Poblaci{oacento}n (opcional)</span>
              <Field name="villaReposo">
                {({ field, meta }) => (
                  <div className="form-group input-wrapper">
                    <input
                      {...field}
                      type="text"
                      className={`form-control form-control-lg ${
                        meta.touched
                          ? meta.error
                            ? "is-invalid"
                            : "is-valid"
                          : ""
                      }`}
                      id="villaReposo"
                      name="villaReposo"
                      placeholder=""
                      autoComplete="off"
                      aria-describedby="villaReposoAyuda"
                    />
                    <label htmlFor="villaReposo" className="control-label">
                      Villa o Poblaci{oacento}n *
                    </label>
                    <small
                      id="villaReposoAyuda"
                      className={`form-text ${
                        meta.touched && meta.error ? "is-invalid" : ""
                      }`}
                    >
                      {" "}
                      {meta.touched && meta.error
                        ? meta.error
                        : "Ej: Villa el Porvenir."}
                    </small>
                  </div>
                )}
              </Field>
              <Field name="regionReposo">
                {({ field, meta }) => (
                  <div className="form-group input-wrapper select">
                    <label htmlFor="regionReposo">
                      Regi{oacento}n
                      <select
                        {...field}
                        className="form-control form-control-lg"
                        id="regionReposo"
                        name="regionReposo"
                        value={regionReposo}
                        onChange={(e) => {
                          selectRegionReposo(e, formik);
                        }}
                      >
                        {" "}
                        {/* eslint-disable-line */}
                        <option value="99">Seleccionar</option>
                        {regionesReposo.map((datos) => (
                          <option value={datos.codigo}>{datos.region}</option>
                        ))}
                      </select>
                    </label>
                    <small
                      id="regionReposoAyuda"
                      className={`form-text ${
                        meta.touched
                          ? meta.error
                            ? "is-invalid"
                            : "is-valid"
                          : ""
                      }`}
                    >
                      {meta.touched && meta.error ? meta.error : ""}
                    </small>
                  </div>
                )}
              </Field>
              <Field name="ciudadReposo">
                {({ field, meta }) => (
                  <div className="form-group input-wrapper select">
                    <label htmlFor="ciudadReposo">
                      Ciudad
                      <select
                        {...field}
                        className="form-control form-control-lg"
                        id="ciudadReposo"
                        name="ciudadReposo"
                        value={ciudadReposo}
                        onChange={(e) => {
                          selectCiudadReposo(e, formik);
                        }}
                      >
                        {" "}
                        {/* eslint-disable-line */}
                        <option value="99">Seleccionar</option>
                        {ciudadesReposo.map((datos) => (
                          <option value={`${datos.codigo}-${datos.provincia}`}>
                            {datos.provincia}
                          </option>
                        ))}
                      </select>
                    </label>
                    <small
                      id="ciudadReposoAyuda"
                      className={`form-text ${
                        meta.touched
                          ? meta.error
                            ? "is-invalid"
                            : "is-valid"
                          : ""
                      }`}
                    >
                      {meta.touched && meta.error ? meta.error : ""}
                    </small>
                  </div>
                )}
              </Field>
              <Field name="comunaReposo">
                {({ field, meta }) => (
                  <div className="form-group input-wrapper select">
                    <label htmlFor="comunaReposo">
                      Comuna
                      <select
                        {...field}
                        className="form-control form-control-lg"
                        id="comunaReposo"
                        name="comunaReposo"
                        value={comunaReposo}
                        onChange={(e) => {
                          selectComunaReposo(e, formik);
                        }}
                      >
                        {" "}
                        {/* eslint-disable-line */}
                        <option value="99">Seleccionar</option>
                        {comunasReposo.map((datos) => (
                          <option value={datos.codigo}>{datos.comuna}</option>
                        ))}
                      </select>
                    </label>
                    <small
                      id="comunaReposoAyuda"
                      className={`form-text ${
                        meta.touched
                          ? meta.error
                            ? "is-invalid"
                            : "is-valid"
                          : ""
                      }`}
                    >
                      {meta.touched && meta.error ? meta.error : ""}
                    </small>
                  </div>
                )}
              </Field>
            </>
          )}

          <span>Teléfono contacto principal</span>
          <Field name="telefonoPrincipal">
            {({ field, meta }) => (
              <div className="form-group input-wrapper">
                <MaskedInput
                  {...field}
                  mask={celularMaskNew}
                  type="text"
                  className={`form-control form-control-lg ${
                    meta.touched ? (meta.error ? "is-invalid" : "is-valid") : ""
                  }`}
                  id="telefonoPrincipal"
                  name="telefonoPrincipal"
                  placeholder="(+56) _ ____ ____"
                  autoComplete="off"
                  aria-describedby="telefonoPrincipalAyuda"
                />
                <label htmlFor="telefonoPrincipal" className="control-label">
                  Teléfono contacto principal
                </label>
                <small
                  id="telefonoPrincipalAyuda"
                  className={`form-text ${
                    meta.touched && meta.error ? "is-invalid" : ""
                  }`}
                >
                  {" "}
                  {meta.touched && meta.error
                    ? meta.error
                    : "Ej. (+56) 9 1234 5678 o (+56) 22 12 34 567"}
                </small>
              </div>
            )}
          </Field>
          <span>Confirmar tel{eacento}fono de contacto principal</span>
          <Field name="telefonoConfirmar">
            {({ field, meta }) => (
              <div className="form-group input-wrapper">
                <MaskedInput
                  {...field}
                  mask={celularMaskNew}
                  type="text"
                  className={`form-control form-control-lg ${
                    meta.touched ? (meta.error ? "is-invalid" : "is-valid") : ""
                  }`}
                  id="telefonoConfirmar"
                  name="telefonoConfirmar"
                  placeholder="(+56) _ ____ ____"
                  autoComplete="off"
                  aria-describedby="telefonoConfirmarAyuda"
                />
                <label htmlFor="telefonoConfirmar" className="control-label">
                  Confirmar tel{eacento}fono de contacto principal
                </label>
                <small
                  id="telefonoConfirmarAyuda"
                  className={`form-text ${
                    meta.touched && meta.error ? "is-invalid" : ""
                  }`}
                >
                  {" "}
                  {meta.touched && meta.error
                    ? meta.error
                    : "Ej. (+56) 9 1234 5678 o (+56) 22 12 34 567"}
                </small>
              </div>
            )}
          </Field>
          <span>Teléfono de contacto secundario (Opcional)</span>
          <Field name="telefonoSecundario">
            {({ field, meta }) => (
              <div className="form-group input-wrapper">
                <MaskedInput
                  {...field}
                  mask={celularMaskNew}
                  type="text"
                  className={`form-control form-control-lg ${
                    meta.touched ? (meta.error ? "is-invalid" : "is-valid") : ""
                  }`}
                  id="telefonoSecundario"
                  name="telefonoSecundario"
                  placeholder="(+56) _ ____ ____"
                  aria-describedby="telefonoSecundarioAyuda"
                />
                <label htmlFor="telefonoSecundario" className="control-label">
                  Teléfono de contacto secundario (opcional)
                </label>
                <small
                  id="telefonoSecundarioAyuda"
                  className={`form-text ${
                    meta.touched && meta.error ? "is-invalid" : ""
                  }`}
                >
                  {" "}
                  {meta.touched && meta.error
                    ? meta.error
                    : "Este campo no es obligatorio."}
                </small>
              </div>
            )}
          </Field>

          <span>Correo electrónico</span>
          <Field name="correo">
            {({ field, meta }) => (
              <div className="form-group input-wrapper">
                <input
                  {...field}
                  type="text"
                  className={`form-control form-control-lg ${
                    meta.touched ? (meta.error ? "is-invalid" : "is-valid") : ""
                  }`}
                  id="correo"
                  name="correo"
                  placeholder=""
                  aria-describedby="correoAyuda"
                />
                <label htmlFor="correo" className="control-label">
                  Correo electrónico
                </label>
                <small
                  id="correoAyuda"
                  className={`form-text ${
                    meta.touched && meta.error ? "is-invalid" : ""
                  }`}
                >
                  {" "}
                  {meta.touched && meta.error
                    ? meta.error
                    : "Ej: aumentatusueldo@afpmodelo.cl"}
                </small>
              </div>
            )}
          </Field>
          <span>Confirmar correo electrónico</span>
          <Field name="correoConfirmar">
            {({ field, meta }) => (
              <div className="form-group input-wrapper">
                <input
                  {...field}
                  type="text"
                  className={`form-control form-control-lg ${
                    meta.touched ? (meta.error ? "is-invalid" : "is-valid") : ""
                  }`}
                  id="correoConfirmar"
                  name="correoConfirmar"
                  placeholder=""
                  aria-describedby="correoConfirmarAyuda"
                />
                <label htmlFor="correoConfirmar" className="control-label">
                  Confirmar correo electrónico
                </label>
                <small
                  id="correoConfirmarAyuda"
                  className={`form-text ${
                    meta.touched && meta.error ? "is-invalid" : ""
                  }`}
                >
                  {" "}
                  {meta.touched && meta.error
                    ? meta.error
                    : "Ej: aumentatusueldo@afpmodelo.cl"}
                </small>
              </div>
            )}
          </Field>
          <Field name="checkCorreo">
            {({ field, meta }) => (
              <div className="form-check">
                <label
                  className="form-check-label mt-auto"
                  htmlFor="checkCorreo"
                >
                  <input
                    type="checkbox"
                    name="checkCorreo"
                    {...field}
                    className="form-check-input"
                  />
                  <small className="checkCorreo">
                    No tengo correo electr{oacento}nico.
                  </small>
                </label>
                <small
                  id="checkCorreoAyuda"
                  className={`form-text ${
                    meta.touched && meta.error ? "is-invalid" : ""
                  }`}
                >
                  {meta.touched && meta.error ? meta.error : ""}
                </small>
              </div>
            )}
          </Field>

          <Field name="prevision">
            {({ field, meta }) => (
              <div className="form-group input-wrapper select">
                <label htmlFor="prevision" className="control-labelx">
                  Previsi{oacento}n de salud
                  <select
                    {...field}
                    className="form-control form-control-lg"
                    id="prevision"
                    name="prevision"
                  >
                    {" "}
                    {/* eslint-disable-line */}
                    <option value="99">Seleccionar</option>
                    {previsiones.map((datos) => (
                      <option value={datos.codigo}>{datos.nombre}</option>
                    ))}
                  </select>
                </label>
                <small
                  id="previsionAyuda"
                  className={`form-text ${
                    meta.touched && meta.error ? "is-invalid" : ""
                  }`}
                >
                  {meta.touched && meta.error ? meta.error : ""}
                </small>
              </div>
            )}
          </Field>

          <p>Nivel educacional:</p>

          <Field name="nivelEducacional">
            {({ field, meta }) => (
              <div className="form-group input-wrapper select">
                <label htmlFor="nivelEducacional">
                  Indique su situación actual:
                  <select
                    {...field}
                    className="form-control form-control-lg"
                    id="nivelEducacional"
                    name="nivelEducacional"
                  >
                    {" "}
                    {/* eslint-disable-line */}
                    <option value="99">Seleccionar</option>
                    {parametrosEducacion.map((datos) => (
                      <option value={datos.idNivAfi}>
                        {datos.descripcion}
                      </option>
                    ))}
                  </select>
                </label>
                <small
                  id="nivelEducacionalAyuda"
                  className={`form-text ${
                    meta.touched ? (meta.error ? "is-invalid" : "is-valid") : ""
                  }`}
                >
                  {meta.touched && meta.error ? meta.error : ""}
                </small>
              </div>
            )}
          </Field>

          <p>Profesión u oficio:</p>

          <Field name="profesion">
            {({ field, meta }) => (
              <div className="form-group input-wrapper select">
                <label htmlFor="profesion">
                  Indique su situación actual:
                  <select
                    {...field}
                    className="form-control form-control-lg"
                    id="profesion"
                    name="profesion"
                  >
                    {" "}
                    {/* eslint-disable-line */}
                    <option value="99">Seleccionar</option>
                    {parametrosOcupacion.map((datos) => (
                      <option value={datos.idProOfiAfi}>
                        {datos.descripcion}
                      </option>
                    ))}
                  </select>
                </label>
                <small
                  id="profesionAyuda"
                  className={`form-text ${
                    meta.touched ? (meta.error ? "is-invalid" : "is-valid") : ""
                  }`}
                >
                  {meta.touched && meta.error ? meta.error : ""}
                </small>
              </div>
            )}
          </Field>

          <p>Antecedentes laborales</p>

          <Field name="antecedentesLaborales">
            {({ field, meta }) => (
              <div className="form-group input-wrapper select">
                <label htmlFor="antecedentesLaborales">
                  Indique su situación laboral actual:
                  <select
                    {...field}
                    className="form-control form-control-lg"
                    id="antecedentesLaborales"
                    name="antecedentesLaborales"
                  >
                    {" "}
                    {/* eslint-disable-line */}
                    <option key="99" value="99">
                      Seleccionar
                    </option>
                    {condicionesLaborales.map((datos) => (
                      <option value={datos["idConAfi"]}>
                        {datos["descripcion"]}
                      </option>
                    ))}
                  </select>
                </label>
                <small
                  id="antecedentesLaboralesAyuda"
                  className={`form-text ${
                    meta.touched ? (meta.error ? "is-invalid" : "is-valid") : ""
                  }`}
                >
                  {meta.touched && meta.error ? meta.error : ""}
                </small>
              </div>
            )}
          </Field>

          {(formik.values.profesion === "2" ||
            formik.values.profesion === "3") &&
            formik.values.antecedentesLaborales === "2" && (
              <>
                <span>Fecha de inicio periodo de desempleo.(Obligatorio) </span>
                <Field name={`fechaInicioDesempleoPrivado`}>
                  {({ field, meta }) => (
                    <div className="form-group input-wrapper">
                      <MaskedInput
                        {...field}
                        mask={fechaMask}
                        type="text"
                        className={`form-control form-control-lg ${
                          meta.touched
                            ? meta.error
                              ? "is-invalid"
                              : "is-valid"
                            : ""
                        }`}
                        name={`fechaInicioDesempleoPrivado`}
                        aria-describedby={`fechaInicioDesempleoPrivadoAyuda`}
                        placeholder="dd/mm/yyyy"
                        autoComplete="off"
                      />
                      <label
                        htmlFor="fechaInicioDesempleoPrivado"
                        className="control-label"
                      >
                        RUT
                      </label>
                      <small
                        id={`fechaInicioDesempleoPrivadoAyuda`}
                        className={`form-text ${
                          meta.touched && meta.error ? "is-invalid" : ""
                        }`}
                      >
                        {meta.touched && meta.error
                          ? meta.error
                          : "Ej: 01/12/1991."}
                      </small>
                    </div>
                  )}
                </Field>
              </>
            )}

          {(formik.values.profesion === "4" ||
            formik.values.profesion === "5") && (
            <>
              <span>Fecha de cese de funciones </span>
              <Field name={`fechaInicioDesempleoPublico`}>
                {({ field, meta }) => (
                  <div className="form-group input-wrapper">
                    <MaskedInput
                      {...field}
                      mask={fechaMask}
                      type="text"
                      className={`form-control form-control-lg ${
                        meta.touched
                          ? meta.error
                            ? "is-invalid"
                            : "is-valid"
                          : ""
                      }`}
                      name={`fechaInicioDesempleoPublico`}
                      aria-describedby={`fechaInicioDesempleoPublicoAyuda`}
                      placeholder="dd/mm/yyyy"
                      autoComplete="off"
                    />
                    <label
                      htmlFor="fechaInicioDesempleoPublico"
                      className="control-label"
                    >
                      RUT
                    </label>
                    <small
                      id={`fechaInicioDesempleoPublicoAyuda`}
                      className={`form-text ${
                        meta.touched && meta.error ? "is-invalid" : ""
                      }`}
                    >
                      {meta.touched && meta.error
                        ? meta.error
                        : "Ej: 01/12/1991."}
                    </small>
                  </div>
                )}
              </Field>
            </>
          )}

          {formik.values.antecedentesLaborales === "3" && (
            <>
              <p>Periodo Licencia Medica.(Opcional) </p>

              <span>Fecha de inicio Licencia Medica</span>
              <Field name={`fechaInicioLicenciaMedica`}>
                {({ field, meta }) => (
                  <div className="form-group input-wrapper">
                    <MaskedInput
                      {...field}
                      mask={fechaMask}
                      type="text"
                      className={`form-control form-control-lg ${
                        meta.touched
                          ? meta.error
                            ? "is-invalid"
                            : "is-valid"
                          : ""
                      }`}
                      name={`fechaInicioLicenciaMedica`}
                      aria-describedby={`fechaInicioLicenciaMedicaAyuda`}
                      placeholder="dd/mm/yyyy"
                      autoComplete="off"
                    />
                    <label
                      htmlFor="fechaInicioLicenciaMedica"
                      className="control-label"
                    ></label>
                    <small
                      id={`fechaInicioLicenciaMedica`}
                      className={`form-text ${
                        meta.touched && meta.error ? "is-invalid" : ""
                      }`}
                    >
                      {meta.touched && meta.error
                        ? meta.error
                        : "Ej: 01/12/1991."}
                    </small>
                  </div>
                )}
              </Field>

              <span>Fecha de termino Licencia Medica</span>
              <Field name={`fechaFinLicenciaMedica`}>
                {({ field, meta }) => (
                  <div className="form-group input-wrapper">
                    <MaskedInput
                      {...field}
                      mask={fechaMask}
                      type="text"
                      className={`form-control form-control-lg ${
                        meta.touched
                          ? meta.error
                            ? "is-invalid"
                            : "is-valid"
                          : ""
                      }`}
                      name={`fechaFinLicenciaMedica`}
                      aria-describedby={`fechaFinLicenciaMedica`}
                      placeholder="dd/mm/yyyy"
                      autoComplete="off"
                    />
                    <label
                      htmlFor="fechaFinLicenciaMedica"
                      className="control-label"
                    ></label>
                    <small
                      id={`fechaFinLicenciaMedicaAyuda`}
                      className={`form-text ${
                        meta.touched && meta.error ? "is-invalid" : ""
                      }`}
                    >
                      {meta.touched && meta.error
                        ? meta.error
                        : "Ej: 01/12/1991."}
                    </small>
                  </div>
                )}
              </Field>
            </>
          )}

          <p>Selecciona que tipo de afiliado o pensionado eres:</p>

          <Field name="afiliadoPensionado">
            {({ field, meta }) => (
              <div className="form-group input-wrapper select">
                <label htmlFor="afiliadoPensionado">
                  Indique su tipo de afiliación actual:
                  <select
                    {...field}
                    className="form-control form-control-lg"
                    id="afiliadoPensionado"
                    name="afiliadoPensionado"
                  >
                    {" "}
                    {/* eslint-disable-line */}
                    <option value="99">Seleccionar</option>
                    {parametrosAfiliadoPensionado.map((datos) => (
                      <option value={datos.idTipAfiPen}>
                        {datos.descripcion}
                      </option>
                    ))}
                  </select>
                </label>
                <small
                  id="afiliadoPensionadoAyuda"
                  className={`form-text ${
                    meta.touched ? (meta.error ? "is-invalid" : "is-valid") : ""
                  }`}
                >
                  {meta.touched && meta.error ? meta.error : ""}
                </small>
              </div>
            )}
          </Field>
          <Field name="radioCobertura">
            {({ field, meta }) => (
              <div className="form-group input-wrapper select">
                <label htmlFor="radioCobertura">
                  ¿Tiene cobertura del Seguro de Invalidez y Sobrevivencia?
                  <Stack direction="horizontal" gap={2}>
                    <div className="form-check">
                      <input
                        {...field}
                        className="form-check-input"
                        type="radio"
                        name="radioCobertura"
                        id="inlineRadio1"
                        value="SI"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="inlineRadio1"
                      >
                        Si
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        {...field}
                        className="form-check-input"
                        type="radio"
                        name="radioCobertura"
                        id="inlineRadio2"
                        value="NO"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="inlineRadio2"
                      >
                        No
                      </label>
                    </div>
                  </Stack>
                </label>
              </div>
            )}
          </Field>
          <Field name="Autoriza">
            {({ field, meta }) => (
              <div className="form-check">
                <label className="form-check-label mt-auto" htmlFor="Autoriza">
                  <input
                    type="checkbox"
                    name="Autoriza"
                    {...field}
                    className="form-check-input"
                    defaultChecked={true}
                  />

                  <strong data-tooltip-id="tooltipInfo">
                    Autorizo a la AFP para que suscriba a mi nombre la Solicitud
                    de Aporte Previsional Solidario.
                  </strong>
                </label>
                <small
                  id="AutorizaAyuda"
                  className={`form-text ${
                    meta.touched && meta.error ? "is-invalid" : ""
                  }`}
                >
                  {meta.touched && meta.error ? meta.error : ""}
                </small>
              </div>
            )}
          </Field>

          <div className="d-flex justify-content-center">
            <div className="col justify-content-center d-flex">
              {isLoading ? (
                <>
                  <button
                    className="btn-custom btn-lg btn-custom-primary"
                    type="button"
                    disabled
                  >
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    />
                  </button>
                </>
              ) : (
                <button
                  type="submit"
                  className="btn-custom btn-lg btn-custom-primary"
                  disabled={!(formik.isValid && formik.dirty)}
                >
                  Continuar
                </button>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col justify-content-center d-flex blue-a">
              <a
                onClick={() => {
                  history.goBack();
                }}
              >
                Volver al paso anterior
              </a>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default FormDatosAfiliados;
