import React, { useState, useEffect } from "react";
import { Formik, Form, Field } from "formik";
import { oacento, uacento } from "../utils/caracteresUTF8";
import { validationSchemaBancaria } from "../utils/validationSchemaForm";
import Alert from "../utils/alert";
import MaskedInput from "react-text-mask";
import { rutMask } from "../utils/inputMask";
import { Collapse } from "react-collapse";
import { useHistory } from "react-router-dom";
import { initialValuesFormFormaPago } from "../utils/initialValues/formFormaPago";
import { HandleSubmitFormFormaPago } from "../utils/handleSubmit/formFormaPago";
import { traerBancos } from "../services/api/bancos";

const FormFormaPago = (props) => {
  let history = useHistory();

  function volver() {
    history.goBack();
  }

  const [alternativa, setAlternativa] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [instituciones, setinstituciones] = useState([]);
  const idPersona = localStorage.getItem("idPersona");
  const idCliente = Number.parseInt(localStorage.getItem("idCliente"));
  const informante = localStorage.getItem("informante");

  const [pagoAlternativo, setPagoAlternativo] = useState(false);

  const handleSubmit = (values) => {
    HandleSubmitFormFormaPago({
      values: values,
      changeLoading: setIsLoading,
      informante: informante,
      alternativa: alternativa,
      idPersona: idPersona,
      idCliente: idCliente,
      history: history,
    });
  };

  useEffect(async () => {
    /***LLAMADA A SERVICIO BANCOS *****/

    const lista_bancos = await traerBancos();

    if (lista_bancos.status === 200 && lista_bancos.data.ok) {
      const array = [];

      lista_bancos.data.resultado.map((datos) => {
        array.push([datos.codBanco, datos.descripcion]);
      });

      setinstituciones(array);
    }
  }, [history.location.pathname]);

  return (
    <Formik
      initialValues={initialValuesFormFormaPago}
      validationSchema={validationSchemaBancaria}
      onSubmit={handleSubmit}
    >
      {(formik) => (
        <Form>
          <div id="accordion">
            <div className="custom-accordion">
              <div className="accordion-header" id="headingOne">
                {/* Radio Cuenta Bancaria  */}
                <Field name="radioButtonP">
                  {({ field }) => (
                    <div className="form-check">
                      <label
                        className="form-check-label mt-auto"
                        htmlFor="bancaria"
                      >
                        <input
                          {...field}
                          type="radio"
                          className="form-check-input"
                          name="radioButtonP"
                          autoComplete="off"
                          value="0"
                        />
                        <small className="bancaria">Cuenta Bancaria</small>
                      </label>
                    </div>
                  )}
                </Field>
              </div>

              {/* Form Cuenta Bancaria, only visible when radio cuenta bancaria P (primero) is selected  */}
              <Collapse isOpened={formik.values.radioButtonP === "0"}>
                <div className="accordion-body">
                  <Field name="rutP">
                    {({ field, meta }) => (
                      <div className="form-group input-wrapper">
                        <label htmlFor="rut" className="control-labelx">
                          Rut
                        </label>
                        <MaskedInput
                          {...field}
                          mask={rutMask}
                          type="text"
                          className={`form-control form-control-lg ${
                            meta.touched
                              ? meta.error
                                ? "is-invalid"
                                : "is-valid"
                              : ""
                          }`}
                          id="rut"
                          name="rutP"
                          aria-describedby="rutAyuda"
                          placeholder=""
                          autoComplete="off"
                        />
                        <small
                          id="rutAyuda"
                          className={`form-text ${
                            meta.touched && meta.error ? "is-invalid" : ""
                          }`}
                        >
                          {meta.touched && meta.error
                            ? meta.error
                            : "Ej. 12.345.678-9."}
                        </small>
                      </div>
                    )}
                  </Field>
                  <Field name="institucionP">
                    {({ field, meta }) => (
                      <div className="form-group input-wrapper">
                        <h1 className="titulo">
                          {" "}
                          Instituci{oacento}n financiera
                        </h1>
                        <select
                          {...field}
                          className="form-control form-control-lg"
                          id="institucion"
                          aria-describedby="institucionAyuda"
                          name="institucionP"
                        >
                          <option value="">Seleccionar</option>
                          {instituciones.sort().map((datos, index) => (
                            <option key={index} value={datos[0]}>
                              {datos[1]}
                            </option>
                          ))}
                        </select>
                        <small
                          id="institucionAyuda"
                          className={`form-text ${
                            meta.touched && meta.error ? "is-invalid" : ""
                          }`}
                        >
                          {meta.touched && meta.error ? meta.error : ""}
                        </small>
                      </div>
                    )}
                  </Field>
                  <Field name="tipoCuentaP">
                    {({ field, meta }) => (
                      <div className="form-group input-wrapper">
                        <h1 className="titulo">Tipo de cuenta</h1>
                        <select
                          {...field}
                          className="form-control form-control-lg"
                          id="tipoCuenta"
                          aria-describedby="tipoCuentaAyuda"
                          name="tipoCuentaP"
                        >
                          <option key={0} value="">
                            Seleccionar
                          </option>
                          <option key={1} value="2">
                            Cuenta Ahorro
                          </option>
                          <option key={2} value="3">
                            Cuenta Chequera electr{oacento}nica
                          </option>
                          <option key={3} value="1">
                            Cuenta Corriente
                          </option>
                          {formik.values.institucionP === "12" ? (
                            <option key={4} value="4">
                              CuentaRUT
                            </option>
                          ) : (
                            <option key={4} value="4">
                              Cuenta Vista
                            </option>
                          )}
                        </select>
                        <small
                          id="tipoCuentaAyuda"
                          className={`form-text ${
                            meta.touched
                              ? meta.error
                                ? "is-invalid"
                                : "is-valid"
                              : ""
                          }`}
                        >
                          {meta.touched && meta.error ? meta.error : ""}
                        </small>
                      </div>
                    )}
                  </Field>
                  <Field name="numeroCuentaP">
                    {({ field, meta }) => (
                      <div className="form-group input-wrapper">
                        <h1 className="titulo">N{uacento}mero de cuenta</h1>
                        <input
                          {...field}
                          type="text"
                          className={`form-control form-control-lg ${
                            meta.touched
                              ? meta.error
                                ? "is-invalid"
                                : "is-valid"
                              : ""
                          }`}
                          id="numeroCuenta"
                          name="numeroCuentaP"
                          placeholder=""
                          autoComplete="off"
                          aria-describedby="numeroCuentaAyuda"
                        />
                        <small
                          id="numeroCuentaAyuda"
                          className={`form-text ${
                            meta.touched && meta.error
                              ? "is-invalid"
                              : `El n${uacento}mero ingresado debe coincidir con el campo anterior.`
                          }`}
                        >
                          {meta.touched && meta.error ? meta.error : ""}
                        </small>
                      </div>
                    )}
                  </Field>
                  <Field name="numeroCuentaPConfirmar">
                    {({ field, meta }) => (
                      <div className="form-group input-wrapper">
                        <h1 className="titulo">
                          Confirmar N{uacento}mero de cuenta
                        </h1>
                        <input
                          {...field}
                          type="text"
                          className={`form-control form-control-lg ${
                            meta.touched
                              ? meta.error
                                ? "is-invalid"
                                : "is-valid"
                              : ""
                          }`}
                          id="numeroCuenta"
                          name="numeroCuentaPConfirmar"
                          placeholder=""
                          autoComplete="off"
                          aria-describedby="numeroCuentaAyuda"
                        />
                        <small
                          id="numeroCuentaAyuda"
                          className={`form-text ${
                            meta.touched && meta.error ? "is-invalid" : ""
                          }`}
                        >
                          {meta.touched && meta.error ? meta.error : ""}
                        </small>
                      </div>
                    )}
                  </Field>
                  <Alert
                    image="warning"
                    className="alert-secondary"
                    message="No está autorizado el depósito en cuentas bipersonales (con más de un titular), ni en cuentas digitales con procesos de apertura no presenciales, evite el rechazo de su solicitud."
                  />
                </div>
              </Collapse>
            </div>

            {/* Radio Vale Vista 1  */}
            <div className="custom-accordion">
              <div className="accordion-header" id="headingThree">
                <Field name="radioButtonP">
                  {({ field }) => (
                    <div className="form-check">
                      <label className="form-check-label mt-auto">
                        <input
                          {...field}
                          type="radio"
                          className="form-check-input"
                          name="radioButtonP"
                          autoComplete="off"
                          value="1"
                          disabled={formik.values.radioButtonS === "1"}
                          onChange={(e) => {
                            field.onChange(e); 
                            if (e.target.value === "1") {
                              formik.setFieldValue("radioButtonS", ""); 
                            }
                          }}
                        />
                        <small className="vistaClass">Vale Vista</small>
                      </label>
                    </div>
                  )}
                </Field>
              </div>
              <Collapse isOpened={formik.values.radioButtonP === "1"}>
                <div className="accordion-body">
                  <Alert
                    image="warning"
                    className="alert-secondary"
                    message="El vale vista será emitido a nombre del mandatario en caso de estar facultado para el cobro. En caso contrario, será emitido a nombre del afiliado o beneficiario de pensión de sobrevivencia."
                  />
                </div>
              </Collapse>
            </div>
          </div>
          <br />

          <div className="row">
            <span className="textoSecundario">
              2- Ingresa la forma de pago alternativa { formik.values.radioButtonP==="0" && "(Obligatoria)"}
            </span>

            <div className="card-body">
              <p className="">
                Esta cuenta será utilizada solo en caso de que no podamos
                realizar el depósito en tu primera opción de pago.
              </p>

              <div className="">
                <div className="custom-accordion">
                  <div className="accordion-header" id="headingFour">
                    {/* Radio Cuenta Bancaria  */}
                    <Field name="radioButtonS">
                      {({ field }) => (
                        <div className="form-check">
                          <label
                            className="form-check-label mt-auto"
                            htmlFor="bancaria"
                          >
                            <input
                              {...field}
                              type="radio"
                              className="form-check-input"
                              name="radioButtonS"
                              autoComplete="off"
                              value="0"
                              disabled={formik.values.radioButtonP === "1"}
                            />
                            <small className="bancaria">Cuenta Bancaria</small>
                          </label>
                        </div>
                      )}
                    </Field>
                  </div>
                  {/* Form Cuenta Bancaria, only visible when radio cuenta bancaria S (segundo) is selected  */}
                  <Collapse isOpened={formik.values.radioButtonS === "0"}>
                    <div className="accordion-body">
                      <Field name="rutS">
                        {({ field, meta }) => (
                          <div className="form-group input-wrapper">
                            <label htmlFor="rut" className="control-labelx">
                              Rut
                            </label>
                            <MaskedInput
                              {...field}
                              mask={rutMask}
                              type="text"
                              className={`form-control form-control-lg ${
                                meta.touched
                                  ? meta.error
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                              id="rut"
                              name="rutS"
                              aria-describedby="rutAyuda"
                              placeholder=""
                              autoComplete="off"
                            />
                            <small
                              id="rutAyuda"
                              className={`form-text ${
                                meta.touched && meta.error ? "is-invalid" : ""
                              }`}
                            >
                              {meta.touched && meta.error
                                ? meta.error
                                : "Ej. 12.345.678-9."}
                            </small>
                          </div>
                        )}
                      </Field>
                      <Field name="institucionS">
                        {({ field, meta }) => (
                          <div className="form-group input-wrapper">
                            <h1 className="titulo">
                              {" "}
                              Instituci{oacento}n financiera
                            </h1>
                            <select
                              {...field}
                              className="form-control form-control-lg"
                              id="institucion"
                              aria-describedby="institucionAyuda"
                              name="institucionS"
                            >
                              <option value="">Seleccionar</option>
                              {instituciones.sort().map((datos, index) => (
                                <option key={index} value={datos[0]}>
                                  {datos[1]}
                                </option>
                              ))}
                            </select>
                            <small
                              id="institucionAyuda"
                              className={`form-text ${
                                meta.touched && meta.error ? "is-invalid" : ""
                              }`}
                            >
                              {meta.touched && meta.error ? meta.error : ""}
                            </small>
                          </div>
                        )}
                      </Field>
                      <Field name="tipoCuentaS">
                        {({ field, meta }) => (
                          <div className="form-group input-wrapper">
                            <h1 className="titulo">Tipo de cuenta</h1>
                            <select
                              {...field}
                              className="form-control form-control-lg"
                              id="tipoCuenta"
                              aria-describedby="tipoCuentaAyuda"
                              name="tipoCuentaS"
                            >
                              <option key={0} value="">
                                Seleccionar
                              </option>
                              <option key={1} value="2">
                                Cuenta Ahorro
                              </option>
                              <option key={2} value="3">
                                Cuenta Chequera electr{oacento}nica
                              </option>
                              <option key={3} value="1">
                                Cuenta Corriente
                              </option>
                              {formik.values.institucionS === "12" ? (
                                <option key={4} value="4">
                                  CuentaRUT
                                </option>
                              ) : (
                                <option key={4} value="4">
                                  Cuenta Vista
                                </option>
                              )}
                            </select>
                            <small
                              id="tipoCuentaAyuda"
                              className={`form-text ${
                                meta.touched
                                  ? meta.error
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                            >
                              {meta.touched && meta.error ? meta.error : ""}
                            </small>
                          </div>
                        )}
                      </Field>
                      <Field name="numeroCuentaS">
                        {({ field, meta }) => (
                          <div className="form-group input-wrapper">
                            <h1 className="titulo">N{uacento}mero de cuenta</h1>
                            <input
                              {...field}
                              type="text"
                              className={`form-control form-control-lg ${
                                meta.touched
                                  ? meta.error
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                              id="numeroCuenta"
                              name="numeroCuentaS"
                              placeholder=""
                              autoComplete="off"
                              aria-describedby="numeroCuentaAyuda"
                            />
                            <small
                              id="numeroCuentaAyuda"
                              className={`form-text ${
                                meta.touched && meta.error ? "is-invalid" : ""
                              }`}
                            >
                              {meta.touched && meta.error ? meta.error : ""}
                            </small>
                          </div>
                        )}
                      </Field>
                      <Field name="numeroCuentaSConfirmar">
                        {({ field, meta }) => (
                          <div className="form-group input-wrapper">
                            <h1 className="titulo">
                              Confirmar n{uacento}mero de cuenta
                            </h1>
                            <input
                              {...field}
                              type="text"
                              className={`form-control form-control-lg ${
                                meta.touched
                                  ? meta.error
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                              id="numeroCuenta"
                              name="numeroCuentaSConfirmar"
                              placeholder=""
                              autoComplete="off"
                              aria-describedby="numeroCuentaAyuda"
                            />
                            <small
                              id="numeroCuentaAyuda"
                              className={`form-text ${
                                meta.touched && meta.error ? "is-invalid" : ""
                              }`}
                            >
                              {meta.touched && meta.error ? meta.error : ""}
                            </small>
                          </div>
                        )}
                      </Field>
                      <Alert
                        image="warning"
                        className="alert-secondary"
                        message="No está autorizado el depósito en cuentas bipersonales (con más de un titular), ni en cuentas digitales con procesos de apertura no presenciales, evite el rechazo de su solicitud."
                      />
                    </div>
                  </Collapse>
                </div>

                {/* Radio Vale Vista 2  */}
                <div className="custom-accordion">
                  <div className="accordion-header" id="headingTwo">
                    <Field name="radioButtonS">
                      {({ field }) => (
                        <div className="form-check">
                          <label className="form-check-label mt-auto">
                            <input
                              {...field}
                              type="radio"
                              className="form-check-input"
                              name="radioButtonS"
                              autoComplete="off"
                              value="1"
                              disabled={formik.values.radioButtonP === "1"}
                            />
                            <small className="vistaClass">Vale Vista</small>
                          </label>
                        </div>
                      )}
                    </Field>
                  </div>
                  {/* Panel alert Vale Vista 2  */}
                  <Collapse isOpened={formik.values.radioButtonS === "1"}>
                    <div className="accordion-body">
                      <Alert
                        image="warning"
                        className="alert-secondary"
                        message="El vale vista será emitido a nombre del mandatario en caso de estar facultado para el cobro. En caso contrario, será emitido a nombre del afiliado o beneficiario de pensión de sobrevivencia."
                      />
                    </div>
                  </Collapse>
                </div>
              </div>
            </div>
          </div>

          <div className="col justify-content-center d-flex">
            {isLoading ? (
              <>
                <button
                  className="btn-custom btn-lg btn-custom-primary"
                  type="button"
                  disabled
                >
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  />
                </button>
              </>
            ) : (
              <button
                type="submit"
                id="continuar"
                className="btn-custom btn-lg btn-custom-primary"
                disabled={!(formik.isValid && formik.dirty)}
              >
                Continuar
              </button>
            )}
          </div>
          <div className="row">
            <div className="col justify-content-center d-flex blue-a ">
              <a onClick={volver}>Volver al paso anterior</a>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default FormFormaPago;
