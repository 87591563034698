import React from 'react';
import '../stack/stack.css'; // Importa el archivo CSS para el estilo

const Stack = ({ className='mt-1' ,direction = 'vertical', gap = 1, children }) => {
  // Convertimos el valor numérico de gap a rem
  const stackStyle = {
    display: 'flex',
    flexDirection: direction === 'vertical' ? 'column' : 'row',
    gap: `${gap}rem`, // Usa el valor numérico como rem
  };

  return <div className = {className} style={stackStyle}>{children}</div>;
};

export default Stack;
