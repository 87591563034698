import axios from "axios";
import configuration from "../Configuration";

export async function genericPost({
    url_endpoint,
    body,
    message_info,
    message_error,
}) {
    console.info(message_info);

    var url = `${configuration.API_URL}${url_endpoint}`;

    return await axios
        .post(url, body, { headers: configuration.JSON_HEADER })
        .catch((e) => {
            console.info(message_error);
            console.error(e);
            return e;
        });
}

export async function genericPostUpload({
    url_endpoint,
    data,
    message_info,
    message_error,
}) {
    console.info(message_info);
    return await axios
        .post(`${configuration.API_URL}${url_endpoint}`, { data: data })
        .catch((e) => {
            console.info(message_error);
            console.error(e);
            return e;
        });
}

export async function genericGet({
    url_endpoint,
    message_info,
    message_error,
}) {
    console.info(message_info);
    return await axios
        .get(`${configuration.API_URL}${url_endpoint}`)
        .catch((e) => {
            console.info(message_error);
            console.error(e);
            return e;
        });
}

export async function genericDelete({
    url_endpoint,
    message_info,
    message_error,
}) {
    console.info(message_info);
    return await axios
        .delete(`${configuration.API_URL}${url_endpoint}`)
        .catch((e) => {
            console.info(message_error);
            console.error(e);
            return e;
        });
}

export async function genericPut({
    url_endpoint,
    body,
    message_info,
    message_error,
}) {
    console.info(message_info);
    return await axios
        .put(`${configuration.API_URL}${url_endpoint}`, body, {
            headers: configuration.JSON_HEADER,
        })
        .catch((e) => {
            console.info(message_error);
            console.error(e);
            return e;
        });
}