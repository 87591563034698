export const initialValuesFormAfiliados = {
    nombres: '',
    apellidoPaterno: '',
    apellidoMaterno: '',
    fechaNacimiento: '',
    genero: '',
    direccion: '',
    numero: '',
    depto: '',
    villa: '',
    region: '',
    ciudad: '',
    comuna: '',
    telefonoPrincipal: '',
    telefonoConfirmar: '',
    telefonoSecundario: '',
    correo: '',
    correoConfirmar: '',
    prevision: '',
    certificadoMedico: false,
    certificadoGES: false,
    calificacionInvalidez: false,
    solicitudAPS: false,
    lugarReposo: '',
    recintoHospitalario: '',
    direccionReposo: '',
    numeroReposo: '',
    deptoReposo: '',
    villaReposo: '',
    regionReposo: '',
    ciudadReposo: '',
    comunaReposo: '',
    nivelEducacional: '',
    profesion: '',
    antecedentesLaborales: '',
    fechaInicioDesempleoPrivado: '',
    fechaInicioDesempleoPublico: '',
    fechaInicioLicenciaMedica: '',
    fechaFinLicenciaMedica: '',
    afiliadoPensionado: '',
    benAutoriza: false,
    radioCobertura: "",
}